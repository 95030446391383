import { HttpRequest } from './http/http.service';
import { apiRequest } from '../_constants/url.constants';

export class ProfileService {
  http = new HttpRequest();

  getProfile(params) {
    return this.http.post(apiRequest.base + apiRequest.getProfile, params);
  }

  updateProfile(params) {
    return this.http.post(apiRequest.base + apiRequest.updateMyProfile, params);
  }

  updateSecurityDetails(params) {
    return this.http.post(apiRequest.base + apiRequest.updateSecurityDetails, params);
  }

}