
export const apiRequest = {
  base: process.env.REACT_APP_BASE_URL,

  baseForInitialIds: process.env.REACT_APP_BASE_INITIAL_ID,

  validationLocation: 'https://zip.getziptastic.com/v2/US',

  initialIds: '/o/instance/v1.0',
  login: '/o/authentication/v1.0/login',
  loginAttempts: '/o/authentication/v1.0/loginFail',
  getContent: '/o/webcontent/v1.0/getContentByName',
  getContents: '/o/webcontent/v1.0/getContents',
  resetPassword: '/o/authentication/v1.0/resetPassword',
  forgotPassword1: '/o/authentication/v1.0/',
  forgotPassword2: '/o/authentication/v1.0/forgotpassword',
  verifyPolicyNumber: '/o/userRegister/v1.0/policyVerification',
  verifyPersonalInfo: '/o/userRegister/v1.0/verify',
  verifySignInCreds: '/o/userRegister/v1.0/save',
  forgotUsername: '/o/authentication/v1.0/forgotusername',
  changePassword: '/o/authentication/v1.0/changePassword',
  verifyCaptcha: '/o/instance/v1.0/isBot',
  getProfile: '/o/account-service/v1.0/MyProfile',
  updateMyProfile: '/o/account-service/v1.0/updateMyProfile',
  getSecurityQuestions: '/o/userRegister/v1.0/getSecurityQuestions',
  updateSecurityDetails: '/o/authentication/v1.0/updateSecurityDetails',
  getPaymentMethods: '/o/payments/v1.0/managePaymentDetails',
  getStatesList: '/o/instance/v1.0/states',
  addPaymentMethod: '/o/payments/v1.0/addPaymentMethod',
  updatePaymentMethod: '/o/payments/v1.0/updatePaymentMethod',
  deletePaymentMethod: '/o/payments/v1.0/deletePaymentMethod',
  getProducts: '/o/payments/v1.0/paymentCenterDetails',
  dashboard: '/o/homepage/v1.0',
  changeFrequencyAutopay: '/o/payments/v1.0/managePaymentPreference',
  submitPayNow: '/o/payments/v1.0/payNow',
  logout: '/o/authentication/v1.0/logout',
  getProductsMain: '/o/products/v1.0/summary',
  getProductDetails: '/o/products/v1.0/details',
  searchClient: '/o/customer-service/v1.0/search/company-id/{companyId}/{search}/{value}/user-id/{userId}',
  impersonateUser: '/o/authentication/v1.0/impersonateUser/company-id/{companyId}/user-id/{userId}',
  deleteUser: '/o/userRegister/v1.0/deleteUser/company-id/{companyId}/username/{userId}',
  viewDocument: '/o/products/v1.0/document/{documentId}/documentSource/{documentType}/documentCategory/{documentCategory}',
  // unsubscribe: '/o/unsubscribe/v1.0',
  unsubscribe: '/o/email-notification/v1.0/unsubscribe/true/userId',
  resubscribe: '/o/email-notification/v1.0/unsubscribe/false/userId',
  insproservice: '/o/insproservice/v1.0',
  registerMigration: '/o/migration/v1.0/',
  getPayNow: '/o/payments/v1.0/payment/paynow',
  getPayNowHomePage: '/o/payments/v1.0/homepage/paynow',
  getPaymentInfo: '/o/payments/v1.0/paymentAmountBreakdown',
  getUpdatedFrequency: '/o/payments/v1.0/autoPaymentPreference',
  getPagesTitle: '/o/webcontent/v1.0/getStructureContents',
  updateLoginInformations: '/o/authentication/v1.0/updateLoginInformations'
};

export const byPassCsUserId = [
  apiRequest.base + apiRequest.getContents,
];

export const SETNRTY_CONFIG = { 
  sentryDSN: process.env.REACT_APP_SENTRY_DSN, 
  sentryENV: process.env.REACT_APP_ENV, 
} 