import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';

import React, { Component } from "react";
import { HashRouter } from "react-router-dom";
import { render } from "react-dom";
import { RouteBody } from "./_routes/app-routes";
import { AuthenticationService } from "./_services/auth.service";
import "bootstrap/dist/css/bootstrap.min.css";
import "./main.scss";
import Footer from "./_components/shared/footer/Footer.jsx";
import Header from "./_components/shared/header/Header.jsx";
import NotFound from "./_components/shared/not-found/Not-Found";
import Loading from "./_components/shared/Loading.jsx";
import { loadReCaptcha } from "react-recaptcha-v3";
import { API_KEYS } from "./_constants/constants";
import { GlobalContextProvider, GlobalContext } from "./_context/global.context";
import { NotificationContextProvider } from "./_context/notifications.context";
import { NotificationContext } from './_context/notifications.context';
import SessionManager from "./_components/shared/session-manager/SessionManager";
import InitOperations from "./_components/shared/init-operations/InitOperations";
import { SharedService } from './_services/shared.service';
import { Helmet } from "react-helmet";
import { SETNRTY_CONFIG } from "./_constants/url.constants";
import * as Sentry from "@sentry/react"
import packageJson from '../package.json';

Sentry.init({
  dsn: SETNRTY_CONFIG.sentryDSN,
  environment: SETNRTY_CONFIG.sentryENV,
  release: packageJson.version
});

class App extends Component {
  authenticationService = new AuthenticationService();
  sharedService = new SharedService();
  originalTimer = 5000;
  originalTimeout;

  state = {
    loading: false,
    appLoaded: false,
    hostFound: false,

    branding: {
      headerLogo: ""
    }
  };

  routeChangeSubscription;

  componentDidMount() {
    // Load google recaptcha
    loadReCaptcha(API_KEYS.RECAPTCHA_SITE_KEY);

    // Call API to get all the required initial ids
    this.getInitialIds();
  }

  setLoading = flag => {
    this.setState({ loading: flag });
  };

  getInitialIds = () => {
    let hostName = document.location.hostname;
    this.authenticationService.getInitialIds(hostName).subscribe(response => {
      if (response.status === 200) {
        localStorage.setItem("initialIds", JSON.stringify(response.data));
        this.setState({
          appLoaded: true,
          hostFound: true,
          branding: {
            headerPrimaryLogo: response.data.brandDetail.primaryLogo,
            headerSecondaryLogo: response.data.brandDetail.secondLogo,
            cssFileUrl: response.data.brandDetail.cssFileUrl,
          }
        });
      } else if (response.status === 404) {
        this.setState({
          appLoaded: true
        });
      } else {
        alert("Not able to fetch global ids");
      }
    });
  };

  checkError = () => {
    this.sharedService.checkServerOnline().subscribe(res => {

    });
  };

  handleIdleTimeout = () => {
    if (this.authenticationService.isUserLoggedIn) {
      // logout user and send to login page.
    }

    this.resetOriginalTimer();
  }

  render() {
    return (
      <>
        {this.state.loading && <Loading />}
        <div className='mainWrapper'>
          <HashRouter basename={"/react-app"}>
            {this.state.appLoaded && this.state.hostFound ? (
              <GlobalContext.Consumer>
                {globalContext => (
                  <NotificationContext.Consumer>
                    {notificationContext => (
                      <>
                        <Helmet>
                          <link rel='stylesheet' type='text/css' href={this.state.branding.cssFileUrl} />
                        </Helmet>
                        <InitOperations notificationContext={notificationContext} globalContext={globalContext} />
                        <SessionManager notificationContext={notificationContext} globalContext={globalContext} setLoading={this.setLoading}/>
                        <Header headerPrimaryLogo={this.state.branding.headerPrimaryLogo} headerSecondaryLogo={this.state.branding.headerSecondaryLogo} notificationContext={notificationContext} globalContext={globalContext} setLoading={this.setLoading} />
                        <RouteBody notificationContext={notificationContext} globalContext={globalContext} setLoading={this.setLoading} />
                        <Footer notificationContext={notificationContext} globalContext={globalContext} setLoading={this.setLoading} />
                      </>
                    )}
                  </NotificationContext.Consumer>
                )}
              </GlobalContext.Consumer>
            ) : ''}

            {this.state.appLoaded && !this.state.hostFound ? (
              <NotFound />
            ) : ''}
          </HashRouter>
        </div></>
    );
  }
}

render(
  <GlobalContextProvider>
    <NotificationContextProvider>
      <App />
    </NotificationContextProvider>
  </GlobalContextProvider>,
  document.getElementById("root"));

