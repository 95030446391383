import React, { Component } from "react";
import { SharedService } from "../_services/shared.service";
import { NOTIFICATIONS, CARDS_TYPE } from '../_constants/constants';
import TagManager from 'react-gtm-module';

export const GlobalContext = React.createContext();

export class GlobalContextProvider extends Component {
  sharedService = new SharedService();
  creditCards = []

  state = {
    states: [],
    deviceHeight: window.innerHeight,
    deviceWidth: window.innerWidth,
    showNavigationBar: true,
    pagesTitle: {}
  };

  componentDidMount() {
    this.getStatesList();

    window.addEventListener('resize', () => {
      this.setState({ deviceWidth: window.innerWidth, deviceHeight: window.innerHeight });
    });
  }

  getStatesList = () => {
    this.sharedService.getStatesList().subscribe(response => {
      if (response.status === 200) {
        this.setState({ states: response.data.items });
      }
    });
  };

  setPagesTitle = (pageTitles) => {
    this.setState({
      pagesTitle: pageTitles
    })
  }

  getCreditCardsRegex = () => {
    let titles = [];
    CARDS_TYPE.forEach((card) => { titles.push(card.regex_WC_key) });
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let creditCards = [];
        response.data.items && response.data.items.forEach(element => {
          let creditCard = CARDS_TYPE.find((card) => card.regex_WC_key === element.title);
          creditCard['regex'] = element.content;
          creditCards.push(creditCard);
        });

        this.creditCards = creditCards
      }
    });
  }

  setShowNavigationBar = (value) => {
    this.setState({
      showNavigationBar: value
    })
  }

  validateCardDetails = (ccNum, ccType) => {
    return new Promise((res, rej) => {
      let selectedCard = this.creditCards.find((card) => card.id === ccType);

      // remove html tags if any
      let tmp = document.createElement("DIV");
      tmp.innerHTML = selectedCard.regex;
      let regexStr = tmp.textContent || tmp.innerText || "";
      let regex = new RegExp(regexStr)

      if (regex.test(ccNum.replaceAll('-', ''))) {
        res({ status: 'SUCCESS', message: '' })
      } else {
        res({ status: 'FAILURE', message: NOTIFICATIONS.INVALID_CREDIT_CARD })
      }
    })
  }

  isCustomerServiceUser = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.hasOwnProperty('csUserid')) {
      return user;
    } else {
      return false;
    }
  }

  getGTMId = () => {
    return JSON.parse(localStorage.getItem("initialIds"));
  }

  triggerGTMDataLayer = (dataLayer) => {
    if (this.getGTMId()) {
      let tagManagerArgs = {
        dataLayer: dataLayer
      }
      TagManager.dataLayer(tagManagerArgs)
    }
  }

  render() {
    return (
      <GlobalContext.Provider
        value={{
          state: this.state,
          getStatesList: this.getStatesList,
          setShowNavigationBar: this.setShowNavigationBar,
          validateCardDetails: this.validateCardDetails,
          getCreditCardsRegex: this.getCreditCardsRegex,
          isCustomerServiceUser: this.isCustomerServiceUser,
          setPagesTitle: this.setPagesTitle,
          triggerGTMDataLayer: this.triggerGTMDataLayer
        }}>
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}
