import React, { useState, useEffect } from "react";
import { withRouter, Link, NavLink } from "react-router-dom";
import SlidingPanel from "react-sliding-side-panel";
import { listenersService } from "../../../_services/listeners.service";
import { AuthenticationService } from "../../../_services/auth.service";
import { SharedService } from "../../../_services/shared.service";
import "./Footer.scss";
import { ARTICLES } from '../../../_constants/constants';
import { StaticContentModal } from '../../../_components/shared/modals/static-content/StaticContentModal'

const Footer = props => {
  let authenticationService = new AuthenticationService();
  let sharedService = new SharedService();
  let isCustomerServiceSite = JSON.parse(localStorage.getItem('initialIds')).isCustomerSite;
  const { showContactUs } = JSON.parse(localStorage.getItem("initialIds"));
  const { showAboutUs } = JSON.parse(localStorage.getItem("initialIds"));
  const logoImageURL = JSON.parse(localStorage.getItem("initialIds")).brandDetail.logoImageUrl;

  const [openPanel, setOpenPanel] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [globalWebContents, setGlobalWebContents] = useState({});
  const [staticContent, setStaticContent] = useState({
    title: '',
    content: ''
  });

  useEffect(() => {
    setIsUserLoggedIn(authenticationService.isUserLoggedIn());
    listenersService.authStateChange().subscribe(data => {
      setIsUserLoggedIn(data ? true : false);
    });
  }, [authenticationService]);

  useEffect(() => {
    globalWC_getContent([
      ARTICLES.CONTACT_US,
      ARTICLES.ABOUT_US,
      ARTICLES.TERMS_OF_SERVICE,
      ARTICLES.PRIVACY_POLICY
    ]);
  }, []);

  const globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items && response.data.items.forEach(element => {
          webContents[element.title] = element.content;
        });
        setGlobalWebContents(webContents);
      }
    });
  };

  const handleStaticCotentClose = () => {
    setShowPopup(false);
  }

  const logout = () => {
    let user = localStorage.getItem("user") || localStorage.getItem("ccuser");
    let access_token = JSON.parse(user).access_token;

    props.setLoading(true);
    authenticationService.logout(access_token).subscribe(response => {
      props.setLoading(false);
      if (response.status === 200) {
        localStorage.removeItem("user");
        localStorage.removeItem("ccuser");
        listenersService.triggreAuthStateChanged(null);
        props.history.push("/");
      }
      else {
        props.notificationContext.processMessages(response.data.messages);
      }
    });
  };

  return (
    <>
      {showPopup && (
        <StaticContentModal
          show={showPopup}
          hide={handleStaticCotentClose}
          title={staticContent.title}
          content={staticContent.content}
          {...props}
        />
      )}
      {!isCustomerServiceSite && props.globalContext.state.deviceWidth >= 768 && (
        <div className='footerWrapper col-md-12 float-left desktopView'>
          <div className='innterFooter '>
            <div className='footerColumn-left col-md-6 col-sm-12 col-xs-12 float-left p-0'>
              <span> © {new Date().getFullYear()}. All rights reserved</span>
            </div>
            <div className='footerColumn-right col-md-6 col-sm-12 col-xs-12 float-left p-0'>
              <Link
                title={"Terms and Conditions"}
                id="ma-db-nav-termsOfService"
                to='#'
                onClick={e => {
                  e.preventDefault();
                  setOpenPanel(false);
                  setStaticContent({
                    title: 'Terms and Conditions',
                    content: globalWebContents[ARTICLES['TERMS_OF_SERVICE']]
                  });
                  setShowPopup(true)
                }}>
                Terms of Service
                              </Link>
              <span>|</span>
              <Link
                title={"Privacy Policy"}
                id="ma-db-nav-privacyPolicy"
                to='#'
                onClick={e => {
                  e.preventDefault();
                  setOpenPanel(false);
                  setStaticContent({
                    title: 'Privacy Policy',
                    content: globalWebContents[ARTICLES['PRIVACY_POLICY']]
                  });
                  setShowPopup(true)
                }}>
                Privacy Policy
                              </Link>
            </div>
          </div>
        </div>
      )}

      {!isCustomerServiceSite && isUserLoggedIn && props.globalContext.state.deviceWidth <= 767 && props.globalContext.state.showNavigationBar && (
        <div className='mobileFooterNav mobileView'>
          <div className='innerfootWapper'>
            <div className='navbarText'>
              <nav className='navbar navbar-expand-lg'>
                <ul className='navbar-nav'>
                  <li className='nav-item'>
                    <NavLink id="ma-mb-nav-home" title={""} className='nav-link' activeClassName='active' to='/dashboard'>
                      <label className='materialicons'>home</label>
                      <label className='navText'>Home</label>
                    </NavLink>
                  </li>

                  <li className='nav-item'>
                    <NavLink id="ma-mb-nav-paymentCenter" title={""} className='nav-link ' activeClassName='active' to='/payment-center'>
                      <label className='materialicons'>attach_money</label>
                      <label className='navText'>Payment Center</label>
                    </NavLink>
                  </li>

                  <li className='nav-item'>
                    <NavLink id="ma-mb-nav-products" title={""} className='nav-link ' activeClassName='active' to='/products-main'>
                      <label className='materialicons'>description</label>
                      <label className='navText'>Products</label>
                    </NavLink>
                  </li>
                  <li className='nav-item notifiacations'>
                    <div>
                      <div
                        onClick={() => {
                          setOpenPanel(true);
                          // setTimeout(()=> {
                          //     document.querySelector(
                          //       ".mobileFooterNav .innerfootWapper .sliding-panel-container .panel"
                          //     ).style.width = "340px";
                          // },100)
                        }}>
                        <label className='ax_default materialicons'>more_horiz</label>
                        <label className='navText'>More</label>
                      </div>
                      <SlidingPanel type={"right"} isOpen={openPanel} size={85}>
                        <div>
                          <div className='headerPanelMore'>
                            <div className='logo d-lg-none'>
                              <img alt={""} className='logoImg' src={process.env.REACT_APP_BASE_URL + logoImageURL} />
                            </div>
                            <button
                              className='close'
                              onClick={e => {
                                e.preventDefault();
                                setOpenPanel(false);
                              }}>
                              x
                            </button>
                          </div>
                          <div className='bodyPanelMore'>
                            <div className='callText'>
                              <div>For questions or customer service</div>
                              <div>
                                call{" "}
                                <Link
                                  title={""}
                                  to='#'
                                  onClick={e => {
                                    e.preventDefault();
                                    setOpenPanel(false);
                                    // props.history.push("/dashboard");
                                  }}>
                                  800-888-8888
                                </Link>
                              </div>
                            </div>
                            <div className='callText'>
                              <div>
                                Trying to file a claim?&nbsp;
                                <Link
                                  title={""}
                                  to='#'
                                  onClick={e => {
                                    e.preventDefault();
                                    setOpenPanel(false);
                                    // props.history.push("/dashboard");
                                  }}>
                                  Click here
                                </Link>
                              </div>
                            </div>
                            <hr className='horizonLine' />
                            <div className='navbarText'>
                              <nav className='navbar navbar-expand-lg'>
                                <ul className='navbar-nav'>
                                  <li className='nav-item'>
                                    <NavLink
                                      id="ma-ms-nav-home"
                                      title={""}
                                      className='nav-link '
                                      to='/dashboard'
                                      activeClassName='active'
                                      onClick={e => {
                                        e.preventDefault();
                                        setOpenPanel(false);
                                        props.history.push("/dashboard");
                                      }}>
                                      <label className='materialicons'>home</label>
                                      <label className='navText'>Home</label>
                                    </NavLink>
                                  </li>

                                  <li className='nav-item'>
                                    <NavLink
                                      id="ma-ms-nav-paymentCenter"
                                      title={""}
                                      className='nav-link '
                                      activeClassName='active'
                                      to='/payment-center'
                                      onClick={e => {
                                        e.preventDefault();
                                        setOpenPanel(false);
                                        props.history.push("/payment-center");
                                      }}>
                                      <label className='materialicons'>attach_money</label>
                                      <label className='navText'>Payment Center</label>
                                    </NavLink>
                                  </li>

                                  <li className='nav-item'>
                                    <NavLink
                                      id="ma-ms-nav-products"
                                      title={""}
                                      className='nav-link '
                                      activeClassName='active'
                                      to='/products-main'
                                      onClick={e => {
                                        e.preventDefault();
                                        setOpenPanel(false);
                                        props.history.push("/products-main");
                                      }}>
                                      <label className='materialicons'>description</label>
                                      <label className='navText'>Products</label>
                                    </NavLink>
                                  </li>
                                  <li className='nav-item notifiacations'>
                                    <NavLink
                                      id="ma-ms-nav-profile"
                                      title={""}
                                      className='nav-link '
                                      activeClassName='active'
                                      to='my-profile'
                                      onClick={e => {
                                        e.preventDefault();
                                        setOpenPanel(false);
                                        props.history.push("/my-profile");
                                      }}>
                                      <label className='materialicons'>person</label>
                                      <label className='navText'>Profile</label>
                                    </NavLink>
                                  </li>

                                  {showAboutUs && <li className='nav-item notifiacations'>
                                    <NavLink
                                      title={""}
                                      className='nav-link '
                                      activeClassName='active'
                                      to='aboutUs'
                                      onClick={e => {
                                        e.preventDefault();
                                        setOpenPanel(false);
                                        setStaticContent({
                                          title: 'About Us',
                                          content: globalWebContents[ARTICLES['ABOUT_US']]
                                        });
                                        setShowPopup(true);
                                      }}>
                                      <label className='materialicons'>people</label>
                                      <label className='navText'>About Us</label>
                                    </NavLink>
                                  </li>
                                  }

                                  {showContactUs && <li className='nav-item notifiacations'>
                                    <NavLink
                                      title={""}
                                      className='nav-link '
                                      activeClassName='active'
                                      to='contactUs'
                                      onClick={e => {
                                        e.preventDefault();
                                        setOpenPanel(false);
                                        setStaticContent({
                                          title: 'Contact Us',
                                          content: globalWebContents[ARTICLES['CONTACT_US']]
                                        });
                                        setShowPopup(true);
                                      }}>
                                      <label className='materialicons'>contact_phone</label>
                                      <label className='navText'>Contact Us</label>
                                    </NavLink>
                                    <hr className='horizonLine bottom' />
                                  </li>
                                  }

                                  {
                                    props.globalContext.isCustomerServiceUser() === false && <li className='nav-item notifiacations'>
                                      <Link
                                        title={""}
                                        id="ma-ms-nav-logout"
                                        className='nav-link '
                                        to='#'
                                        onClick={e => {
                                          e.preventDefault();
                                          setOpenPanel(false);
                                          logout();
                                          // props.history.push("/my-profile");
                                        }}>
                                        <label className='materialicons'>logout</label>
                                        <label className='navText'>Logout</label>
                                      </Link>
                                    </li>
                                  }

                                </ul>
                              </nav>
                            </div>
                            <div className='footerPanelMore'>
                              <Link
                                title={"Privacy Policy"}
                                id="ma-ms-nav-privacyPolicy"
                                to='#'
                                onClick={e => {
                                  e.preventDefault();
                                  setOpenPanel(false);
                                  setStaticContent({
                                    title: 'Privacy Policy',
                                    content: globalWebContents[ARTICLES['PRIVACY_POLICY']]
                                  });
                                  setShowPopup(true)
                                }}>
                                Privacy Policy
                              </Link>
                              <Link
                                title={"Terms and Conditions"}
                                id="ma-ms-nav-termsOfService"
                                to='#'
                                onClick={e => {
                                  e.preventDefault();
                                  setOpenPanel(false);
                                  setStaticContent({
                                    title: 'Terms and Conditions',
                                    content: globalWebContents[ARTICLES['TERMS_OF_SERVICE']]
                                  });
                                  setShowPopup(true)
                                }}>
                                Terms and Conditions
                              </Link>
                            </div>
                          </div>
                        </div>
                      </SlidingPanel>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

        </div>
      )}
    </>
  );
};

// export default Footer;
export default withRouter(Footer);
